import React from "react"
import { graphql } from "gatsby"
import Container from "../components/layouts/container/Container"
import Layout from "./../components/layouts/Default"
import Button from "../components/commons/Button"
import Logo from "../components/commons/svgs/SvgLogo"

export default function page({ location, data }) {
  return (
    <Layout
      location={location}
      title="Instagram Links | Die Summe aller Dinge"
      desc=""
      disableHeader
    >
      <Container className="my-8">
        <Logo className="h-16 mx-auto mb-6" black />

        {data.allCockpitInstagram.nodes.map((entry, i) => {
          return (
            <Button
              text={entry.linkname.value}
              href={entry.link.value}
              className="mb-3"
            />
          )
        })}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allCockpitInstagram {
      nodes {
        link {
          type
          value
        }
        linkname {
          type
          value
        }
        id
      }
    }
  }
`
